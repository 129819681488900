const appConfig = {
    development: { 
        apiPrefix: '/api',
        authenticatedEntryPath: '/home',
        unAuthenticatedEntryPath: '/sign-in',
        mustEntryPath: '/store/selection',
        tourPath: '/',
        locale: 'en',
        enableMock: false,
        rolesType: {
            SA_REP: 'Sales Representative',
            SUPERVISOR_SA_REP: 'Supervisor Sales Representative'
        },
        dateTimeFormat: 'YYYY-MM-DD',
        MODULE_ALLOW: {
            RSO : true,
            RTO : true,
            STOCKTAKE: true,
        }
    },
    staging: { 
        apiPrefix: '/api',
        authenticatedEntryPath: '/home',
        unAuthenticatedEntryPath: '/sign-in',
        mustEntryPath: '/store/selection',
        tourPath: '/',
        locale: 'en',
        enableMock: false,
        rolesType: {
            SA_REP: 'Sales Representative',
            SUPERVISOR_SA_REP: 'Supervisor Sales Representative'
        },
        dateTimeFormat: 'YYYY-MM-DD',
        MODULE_ALLOW: {
            RSO : true,
            RTO : true,
            STOCKTAKE: true,
        }
    },
    production : {
        apiPrefix: '/api',
        authenticatedEntryPath: '/home',
        unAuthenticatedEntryPath: '/sign-in',
        mustEntryPath: '/store/selection',
        tourPath: '/',
        locale: 'en',
        enableMock: false,
        rolesType: {
            SA_REP: 'Sales Representative',
            SUPERVISOR_SA_REP: 'Supervisor Sales Representative'
        },
        dateTimeFormat: 'YYYY-MM-DD',
        MODULE_ALLOW: {
            RSO : false,
            RTO : false,
            STOCKTAKE: true,
        }
    }
}

export default appConfig
