import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const { data } = await apiSignIn(values)
              if (data) {
                const { token, roles, username, id, name, avatar, roles_type } = data.data
                dispatch(onSignInSuccess(token))
                if (roles == 'SUPERADMIN' || roles == 'ADMIN') {
                    //special_email
                    dispatch(
                        setUser({
                            avatar: avatar,
                            username: username,
                            authority: [roles, username],
                            email: username,
                            name: name,
                            id: id,
                            role: roles,
                            roles_type: roles_type
                        })
                    )
                    // const redirectUrl = query.get(REDIRECT_URL_KEY);
                    // navigate(redirectUrl ? redirectUrl : appConfig.otherEntryPath);
                    navigate(appConfig[process.env.NODE_ENV].mustEntryPath)
                }else {
                    dispatch(
                      setUser({
                        avatar: avatar,
                        username: username,
                        authority: [roles,username],
                        email: username,
                        name: name,
                        id: id,
                        role: roles,
                        roles_type: roles_type
                      })
                    );

                    navigate(appConfig[process.env.NODE_ENV].mustEntryPath); 
                  }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                // navigate(
                //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                // )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) { 
            if (errors && errors.response.status === 422) {
                return {
                    status: 'failed',
                    message:
                        errors?.response?.data?.errors?.error ||
                        errors.toString(),
                }
            }
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig[process.env.NODE_ENV].authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        localStorage.clear()
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
